<template>

<router-view></router-view>

</template>

<script>

export default {
    name: "App",

}
</script>

<style lang="less">
body{
  background-color: #f2f4f3;
}
</style>
